<template>
    <v-container grid-list-md class="grey lighten-4 fill-height">
        <v-layout row wrap>
            <v-flex xs4>
                <v-card outlined class="primary--border">
                    <v-card-text class="pb-0">
                        <v-text-field
                                outlined dense
                                autocomplete="off"
                                label="Name"
                                required class="pa-0"
                                v-model="conference.name"
                                name="classname"
                                disabled
                        />
                        <v-text-field v-if="conference.subject"
                                      outlined dense
                                      autocomplete="off"
                                      label="Subject"
                                      required class="pa-0"
                                      v-model="conference.subject.name"
                                      name="classname"
                                      disabled/>
                        <v-text-field
                                outlined dense
                                autocomplete="off"
                                label="Username"
                                required class="pa-0"
                                v-model="userInfo.displayName"
                                name="username"
                                disabled/>
                        <v-text-field
                                outlined dense
                                autocomplete="off"
                                label="Email/Phone"
                                required class="pa-0"
                                hide-details
                                v-model="userInfo.email"
                                name="username"
                                disabled/>
                        <v-checkbox class="ml-2" hide-details dense v-model="config.startWithAudioMuted" inset
                                    label="Audio Muted"></v-checkbox>
                        <v-checkbox class="ml-2" hide-details dense v-model="config.startWithVideoMuted" inset
                                    label="Video Muted"></v-checkbox>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn v-if="isLive" outlined color="primary" class="mt-2" @click="leave">Leave</v-btn>
                        <v-btn v-else outlined color="primary" class="mt-2" @click="join">Join</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                <!-- {{ users }} -->
            </v-flex>
            <v-flex xs8 style="height: calc(100vh - 132px)">
                <v-card class="primary--border" outlined style="height: 100%">
                    <v-card-text id="live-class" style="height: 100%"></v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapActions, mapState} from 'vuex'

    // const domain = 'conference.eacademynepal.com';
    const domain = 'meet.jit.si';
    export default {
        data() {
            return {
                users: [],
                isLive: false,
                conference: {},
                subject: 'subject-name',
                userInfo: {
                    displayName: 'eAcademian',
                    email: 'info@eacademynepal.com'
                },
                meet: null,
                config: {
                    // liveStreamingEnabled: true,
                    startWithAudioMuted: false,
                    startWithVideoMuted: false,
                    enableWelcomePage: false,
                    enableClosePage: true,
                    disable1On1Mode: true,
                    remoteVideoMenu: {
                        // If set to true the 'Kick out' button will be disabled.
                        disableKick: true
                    },
                    disableRemoteMute: true,
                },
                interfaceConfig: {
                    DEFAULT_BACKGROUND: '#1565c0',
                    DISABLE_VIDEO_BACKGROUND: true,
                    VERTICAL_FILMSTRIP: true,
                    filmStripOnly: false,
                    FILM_STRIP_MAX_HEIGHT: 120,
                    CONNECTION_INDICATOR_DISABLED: true,
                    HIDE_KICK_BUTTON_FOR_GUESTS: true,
                    MOBILE_APP_PROMO: false,
                    NATIVE_APP_NAME: 'We Meet',
                    PROVIDER_NAME: 'We Meet',
                    SHOW_CHROME_EXTENSION_BANNER: false,
                    SHOW_JITSI_WATERMARK: false,
                    SHOW_WATERMARK_FOR_GUESTS: false,
                    DISPLAY_WELCOME_PAGE_CONTENT: false,
                    GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,
                    JITSI_WATERMARK_LINK: 'https://eacademynepal.com',
                    DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,
                    TOOLBAR_BUTTONS: []
                },
            }
        },
        computed: {
            ...mapState(['batch', 'user'])
        },
        watch: {
            'config.startWithAudioMuted': function (value) {
                this.toggleAudio();
            },
            'config.startWithVideoMuted': function (value) {
                this.toggleVideo();
            }
        },
        mounted() {
            let user = this.$auth.user();
            this.userInfo.displayName = user.full_name;
            this.userInfo.email = user.email || user.primary_mobile;
            this.get();
            const TOOLBAR_BUTTONS = [
                'microphone',
                'camera',
                // 'closedcaptions',
                'desktop',
                'fullscreen',
                // 'fodeviceselection',
                'hangup',
                'profile',
                'chat',
                // 'recording',
                // 'livestreaming',
                // 'etherpad',
                // 'sharedvideo',
                // 'settings',
                'raisehand',
                // 'videoquality',
                // 'filmstrip',
                // 'invite',
                // 'feedback',
                // 'stats',
                'shortcuts',
                'tileview',
                // 'videobackgroundblur',
                // 'download',
                // 'help',
                // 'e2ee',
            ];
            if (this.$auth.loggedInRole().slug !== 'student') {
                TOOLBAR_BUTTONS.push('security');
                this.config.remoteVideoMenu.disableKick = false;
                this.config.disableRemoteMute = false;
            }
            this.interfaceConfig.TOOLBAR_BUTTONS = TOOLBAR_BUTTONS;
        },
        methods: {
            get() {
                this.$rest.get(`/api/conference/${this.$route.params.classId}`).then(({data}) => {
                    this.conference = data;
                }).catch(err => {
                    if (err.response && err.response.status === 404) {
                        this.$events.fire('notification', {message: err.response.data.message, status: 'error'});
                        this.$router.push({
                            name: 'live-class',
                        });
                    }
                });
            },
            join() {
                if (this.meet == null) {
                    let vm = this;
                    let api = new JitsiMeetExternalAPI(domain, {
                        roomName: this.conference.code,
                        width: '100%',
                        height: '100%',
                        userInfo: {
                            email: this.userInfo.email,
                            displayName: this.userInfo.displayName,
                        },
                        configOverwrite: this.config,
                        interfaceConfigOverwrite: this.interfaceConfig,
                        // jwt: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTMzNywidXNlcm5hbWUiOiJqb2huLmRvZSJ9.EvTdOJS-fbffGHLyND3BMDwWE22zUBOCRspPZEHlNEw',
                        parentNode: document.querySelector('#live-class')
                    });
                    api.executeCommand('subject', this.conference.subject.name);

                    /* let api = new JitsiMeetExternalAPI(domain, {
                        roomName: this.roomName,
                        userInfo: this.userInfo,
                        configOverwrite: this.configOverwrite,
                        width: '100%',
                        height: '100%',
                        parentNode: document.querySelector('#live-class')
                    });
                    this.meet = api; */

                    api.addEventListener('videoConferenceLeft', function (e) {
                        setTimeout(() => {
                            api.dispose();
                        }, 25);
                    });

                    api.addEventListener('videoConferenceJoined', function (e) {
                        vm.users.push({
                            userId: vm.$auth.user().id,
                            clientId: e.id,
                            displayName: e.displayName,
                            roomName: e.roomName
                        });
                        vm.isLive = true;
                    });

                    api.addEventListener('participantJoined', function (e) {
                        vm.users.push({
                            // userId: this.$auth.user().id,
                            clientId: e.id,
                            displayName: e.displayName,
                            // roomName: e.roomName
                        })
                    });
                    api.addEventListener('displayNameChange', function (params) {
                            return this.executeCommand('displayName', vm.userInfo.displayName);
                        },
                    );

                    this.meet = api;
                }
            },
            leave() {
                if (this.meet) {
                    this.meet.executeCommand('hangup');
                    this.isLive = false;
                    setTimeout(() => {
                        this.meet.dispose();
                        this.meet = null;
                    }, 25)
                }
            },
            toggleAudio() {
                if (this.meet) this.meet.executeCommand('toggleAudio');
            },
            toggleVideo() {
                if (this.meet) this.meet.executeCommand('toggleVideo');
            }
        }
    }
</script>
<style lang="scss">
    .premeeting-screen .content input.field {
        display: none !important;
    }
</style>